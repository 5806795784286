import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import {APP_ID, NgModule} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {AppRoutingModule, PDFModule, PageLayoutModule, PageSlotModule, VideoModule} from '@spartacus/storefront';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { CustomLocalizedRoutingModule } from './custom/custom-localized-routing/custom-localized-routing.module';
import { CustomModule } from './custom/custom-module';
import { SpartacusModule } from './spartacus/spartacus.module';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    CustomLocalizedRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    CustomModule,
    PageLayoutModule,
    PageSlotModule,
    VideoModule,
    PDFModule,
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !environment.production,
    connectInZone: true}),
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: "serverPrivateApp",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
