export const commonSpanish = {
  es: {
    // lang
    common: {
      carousel: {
        product: {
          btn: 'Ver más',
        },
        previousSlide: 'Diapositiva anterior',
        nextSlide: 'Siguiente diapositiva',
      },
      common: {
        invalidEmailErrorMessage: 'Email no es válido!',
        validateDocIdentifierAndTotalAmount: 'Para pedidos de más de 3.000€ es obligatorio añadir un NIF.',
        cancel: 'Cancelar',
        delete: 'Borrar',
        remove: 'Eliminar',
        edit: 'Editar',
        back: 'Volver',
        submit: 'Enviar',
        continue: 'Continuar',
        save: 'Guardar',
        done: 'Realizado',
        home: 'Home',
        noData: 'Sin datos',
        confirm: 'Confirmar',
        more: 'Más',
        close: 'Cerrar',
        accept: 'Aceptar',
        readMore: 'Leer más',
        tiendaOnline: 'tienda-online',
        menu: 'Menú',
        reset: 'Restablecer',
        search: 'Buscar',
        loaded: 'Cargado',
      },
      pageMetaResolver: {
        category: {
          title: '{{count}} resultado para {{query}}',
          title_plural: '{{count}} resultados para {{query}}',
        },
        checkout: {
          title: 'Checkout {{count}} artículo',
          title_plural: 'Checkout {{count}} artículos',
        },
        search: {
          title: '{{count}} artículos',
          title_plural: '{{count}} resultados para "{{query}}"',
          findProductTitle: '{{count}} resultado para el cupón "{{coupon}}"',
          findProductTitle_plural: '{{count}} resultados para el cupón "{{coupon}}"',
          empty: 'La búsqueda no ha devuelto ningún resultado para {{ freeTextSearch }}.',
        },
        product: {
          description: '{{description}}',
          heading: '{{heading}}',
          title: '{{title}}',
        },
      },
      spinner: {
        loading: 'Cargando...',
      },
      navigation: {
        shopAll: 'Ver todo {{ navNode}} >',
      },
      searchBox: {
        placeholder: 'Buscar',
        help: {
          insufficientChars: 'Por favor, escriba más caracteres',
          noMatch: 'No se han podido encontrar resultados',
          exactMatch: '{{ term }}',
          empty: 'Pregúntanos',
        },
        ariaLabelSuggestions: 'sugerencias de escritura',
        productSearch: 'Buscar un producto',
        initialDescription:
          'Cuando estén disponibles los resultados de autocompletar, utilice las flechas arriba y abajo para revisar e introduzca para seleccionar. Usuarios de dispositivos táctiles, exploren tocando o con gestos de deslizamiento.',
      },
      sorting: {
        date: 'Fecha',
        orderNumber: 'Número de Pedido',
        rma: 'Número de Devolución',
        replenishmentNumber: 'Número de Reposición',
        nextOrderDate: 'Fecha del Próximo Pedido',
        billDate: 'Fecha factura',
        billDueDate: 'Fecha vencimiento',
        byCodeAsc: 'Nº factura ascendente',
        byCodeDesc: 'Nº factura descendente',
        byDateAsc: 'Fecha factura ascendente',
        byDateDesc: 'Fecha factura descendente',
        byDueDateAsc: 'Fecha vencimiento ascendente',
        byDueDateDesc: 'Fecha vencimiento descendente',
        newsDate: 'Fecha de la noticia*',
        newsTitle: 'Título de la noticia*',
      },
      httpHandlers: {
        badRequest: {
          bad_credentials: 'Email y/o contraseña incorrectos. Por favor, inicie sesión de nuevo.',
          el_usuario_está_deshabilitado: 'Usuario deshabilitado. Por favor, pulse en recuperar contraseña para restablecer la cuenta.',
          user_is_disabled: 'Usuario deshabilitado. Por favor, pulse en recuperar contraseña para restablecer la cuenta.',
          le_compte_utilisateur_est_désactivé: 'Usuario deshabilitado. Por favor, pulse en recuperar contraseña para restablecer la cuenta.',
          usuário_desabilitado: 'Usuario deshabilitado. Por favor, pulse en recuperar contraseña para restablecer la cuenta.'
        },
        badGateway: 'Se ha producido un error en el servidor. Por favor, inténtelo de nuevo más tarde.',
        badRequestPleaseLoginAgain: 'Email y/o contraseña incorrectos. Por favor, inicie sesión de nuevo.',
        badRequestOldPasswordIncorrect: 'Contraseña antigua incorrecta.',
        badRequestGuestDuplicateEmail:
          'El correo electrónico ya existe. Por favor, inicie sesión o introduzca un correo electrónico diferente.',
        conflict: 'Ya existe.',
        forbidden:
          'No está autorizado para realizar esta acción. Por favor, póngase en contacto con su administrador si cree que esto es un error.',
        gatewayTimeout: 'El servidor no responde, por favor, intentelo más tarde.',
        internalServerError:
          'Se ha producido un error interno del servidor. Por favor, inténtelo de nuevo más tarde.',
        sessionExpired: 'Su sesión ha caducado. Por favor, entra en la cuenta de nuevo.',
        unknownError: 'Se produjo un error desconocido',
        unauthorized: {
          common: 'Se produjo un error de autorización desconocido',
          invalid_client: 'Credenciales incorrectas',
        },
        validationErrors: {
          missing: {
            bankAccountNumber: 'El campo IBAN es obligatorio',
            card_cardType:
              'La tarjeta de crédito seleccionada no es compatible. Por favor, seleccione otra.',
            card_accountNumber: 'El número de tarjeta de crédito introducido no es válido.',
            card_cvNumber: 'El código de seguridad introducido no es válido.',
            card_expirationMonth:
              'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
            card_expirationYear: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
            billTo_firstName: 'El nombre introducido no es válido.',
            billTo_lastName: 'El apellido introducido no es válido.',
            billTo_street1: 'La dirección introducida no es válida.',
            billTo_street2: 'La dirección introducida no es válida.',
            billTo_city: 'La ciudad introducida no es válida para esta tarjeta de crédito.',
            billTo_state: 'El estado/provincia introducido no es válido para esta tarjeta de crédito.',
            billTo_country: 'El país introducido no es válido para esta tarjeta de crédito.',
            billTo_postalCode: 'El código postal no es válido para esta tarjeta de crédito.',
            country: {
              isocode: 'País faltante',
            },
            firstName: 'El nombre es obligatorio',
            lastName: 'El apellido es obligatorio',
            password: 'La contraseña es obligatoria',
            firstNameLastName: 'El nombre y apellido es obligatorio',
            uid: 'El email es obligatorio',
            code: 'El código es obligatorio',
            email: 'El email es obligatorio',
            iban: 'EL campo IBAN es obligatorio',
            documentIdentifier: 'El campo NIF es obligatorio',
            postalCode: 'El campo código postal es obligatorio',
            defaultPaymentAddress: {
              phone: 'El campo código postal es obligatorio',
              firstName: 'El campo Nombre es obligatorio',
              lastName: 'El campo Apellido es obligatorio',
              password: 'El campo password es obligatorio',
              line1: 'El campo dirección es obligatorio',
              town: 'El campo ciudad es obligatorio',
              postalCode: 'El campo código postal es obligatorio',
              country: {
                isocode: 'El campo país es obligatorio',
              },
              toCustomersUid: 'Missing customers email address',
            },
            newUid: 'Verifique la información del usuario',
            message: 'El mensaje es obligatorio',
            subject: 'El asunto es obligatorio',
            orderCode: 'El número de pedido es obligatorio',
            productsAffected: 'El campo Productos y Cantidades afectadas es obligatorio',
            qualityProblem: 'El campo Problema de calidad es obligatorio',
            requiredAction: 'El campo Acción requerida es obligatorio',
          }
          ,
          invalid: {
            subject: 'El asunto introducido no es válido.',
            message: 'El mensaje introducido no es válido.',
            bankAccountNumber: 'El campo IBAN no es válido',
            card_expirationMonth:
              'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
            firstName: 'El nombre introducido no es válido.',
            lastName: 'El apellido introducido no es válido.',
            firstNameLastName: 'El nombre y apellido introducido no es válido',
            password: 'La contraseña introducida no es válida, debe tener un mínimo de seis caracteres, con una letra mayúscula, un número, un símbolo',
            uid: 'El email no es válido.',
            code: 'El código no es válido.',
            email: 'El email no es válido.',
            iban: 'EL campo IBAN no es válido',
            sessionCart: 'No se ha configurado la información de pago.',
            documentIdentifier: 'El NIF no es válido.',
            postalCode: 'El código postal no es válido',
            defaultPaymentAddress: {
              phone: 'El campo código postal no es válido',
              firstName: 'El campo Nombre no es válido',
              lastName: 'El campo Apellido no es válido',
              password: 'El campo password no es válido',
              line1: 'El campo dirección no es válido',
              town: 'El campo ciudad no es válido',
              postalCode: 'El campo código no es válido',
              country: {
                isocode: 'El campo país no es válido',
              },
            },
          },
        },
        CartError: {
          CartError: 'Error al simular la orden',
        },
        cartModification: {
          giftBoxError: {
            label: 'No se ha alcanzado el mínimo de productos para completar la caja regalo.',
            entries: '',
          },
          lowStock: {
            label: '',
            entries: 'Se ha ajustado a {{quantity}} unidades el producto siguiente por falta de stock disponible: {{product}}.',
          },
          noStock: {
            label: 'Se ha eliminado el siguiente producto del carro por falta de stock disponible: ',
            entries: '{{product}}.',
          },
          unavailable: {
            label: 'Se han eliminado los artículos que no están disponibles para este destino: ',
            entries: '{{product}}.',
          },
        },
        cartNotFound: 'Carrito no encontrado.',
        invalidCodeProvided: 'Código no válido.',
        notActiveCodeProvided: 'Código no válido actualmente.',
        voucherExceeded: '{{voucherCode}}'
      },
      miniCart: {
        item: '{{count}} artículo en tu carrito',
        item_plural: '{{count}} artículos en tu carrito',
        total: '{{total}}',
        count: '{{count}}',
        preview: {
          empty: 'NO TIENES NINGÚN ARTÍCULO EN TU CARRITO.',
          proceedToCheckout: 'Tramitar pedido',
          viewCart: 'Ver y editar carrito',
        },
      },
      // deprecated since 3.2, moved to @spartacus/user/account/assets
      miniLogin: {
        userGreeting: 'Hola, {{name}}',
        signInRegister: 'Iniciar sesión / Registro',
      },
      skipLink: {
        skipTo: 'Saltar a',
        labels: {
          header: 'Cabecera',
          main: 'Contenido principal',
          footer: 'Pie de página',
          productFacets: 'Facetas del Producto',
          productList: 'Lista de Productos',
        },
      },
      formErrors: {
        nifError: 'Nombre y Apellidos no coinciden con su NIF. Modifíquelos en la sección “Mi información”  y haga click en continuar nuevamente',
        globalMessage: 'El formulario que está intentando enviar contiene errores.',
        required: 'Este campo es obligatorio',
        cxInvalidEmail: 'El formato del email es incorrecto',
        cxInvalidPassword: 'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
        cxPasswordsMustMatch: 'Los campos de la contraseña deben coincidir',
        cxEmailsMustMatch: 'Los campos del email deben coincidir',
        cxStarRatingEmpty: 'El campo de calificación es obligatorio',
        cxNoSelectedItemToCancel: 'Seleccione al menos un elemento',
        cxNegativeAmount: 'El importe debe ser igual o superior a cero',
        cxContainsSpecialCharacters: 'El campo no puede contener caracteres especiales',
        cxIban: 'El campo IBAN es incorrecto',
        cxNif: 'El campo NIF es incorrecto para el país seleccionado',
        cxCif: 'El campo NIF es incorrecto para el país seleccionado. Solo se puede introducir un NIF de persona física',
        cxPostalCode: 'El código postal es incorrecto para la zona - país seleccionado',
        email: 'El formato del email es incorrecto',
        maxlength: 'Has introducido más caracteres de los permitidos',
        maxlengthWithNumber: 'Este campo debe contener como máximo {{maxLength}} caracteres',
        combinedMaxlength: '{{ firstControl }} y {{ secondControl }} combinados deben tener como máximo {{maxLength}} caracteres (actualmente {{actualLength}})',
        date: {
          required: 'Este campo es obligatorio',
          min: 'La fecha no puede ser anterior a {{min}}',
          max: 'La fecha no puede ser posterior a {{max}}',
          pattern: 'Utilice el formato de fecha yyyy-mm-dd',
          invalid: 'Utilice una fecha válida',
        },
        minlength: 'Este campo debe contener al menos {{minLength}} caracteres',
        min: 'No llega al mínimo',
      },
      errorHandlers: {
        scriptFailedToLoad: 'Error al cargar el script.',
        refreshThePage: 'Por favor, recarga la página.',
      },
    },
    shipping: {
      shipping: {
        headerText: {
          destiny: 'destino',
        },
      },
    },
    userProfile: {
      updateEmailForm: {
        newEmailAddress: {
          label: 'Nuevo email',
          placeholder: 'Introduzca email',
        },
        confirmNewEmailAddress: {
          label: 'Confirmar nuevo email',
          placeholder: 'Introduzca email',
        },
        enterValidEmail: 'Por favor, introduzca un email válido.',
        bothEmailMustMatch: 'Ambos emails deben coincidir',
        password: {
          label: 'Contraseña',
          placeholder: 'Introducir contraseña',
        },
        pleaseInputPassword: 'Por favor, introduzca la contraseña',
        emailUpdateSuccess: 'Éxito. Inicia sesión con {{ newUid }}',
      },
      register: {
        contactInfo: 'INFORMACIÓN PERSONAL',
        addressInfo: 'INFORMACIÓN PARA INICIAR TU SESIÓN',
        confirmPassword: {
          action: 'Confirmar contraseña',
          label: 'Confirmar contraseña',
          placeholder: 'Confirmar contraseña',
        },
        managementInMyAccount: 'Gestión en Mi Cuenta.',
        termsAndConditions: 'Términos y condiciones',
        signIn: 'Ya tengo cuenta. Iniciar sesión',
        register: 'Crear una cuenta',
        confirmNewPassword: 'Confirmar contraseña',
        resetPassword: 'Cambiar contraseña',
        createAccount: 'Crear cuenta',
        title: 'Título',
        name: {
          label: 'Nombre y apellidos',
          placeholder: 'Nombre y apellidos',
        },
        firstName: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
        lastName: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
        emailAddress: {
          label: 'Correo electrónico',
          placeholder: 'Introduce un correo electrónico válido',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        newPassword: 'Nueva contraseña',
        emailMarketing:
          'Utilizar mis datos personales para recibir boletines informativos por email para campañas de marketing. Para cambiar su configuración, vaya a Gestión de Consentimiento en Mi Cuenta.',
        confirmThatRead: 'ACEPTO EL TRATAMIENTO DE MIS DATOS PERSONALES PARA LAS ACTIVIDADES QUE A CONTINUACIÓN SE INDICAN',
        selectTitle: 'Seleccionar título',
        passwordMinRequirements:
          'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
        bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
        titleRequired: 'Se requiere título.',
        postRegisterMessage: 'Por favor, inicie sesión con las credenciales proporcionadas.',
        newsletter: "ACEPTO SUSCRIBIRME A LA NEWSLETTER",
      },
      forgottenPassword: {
        resetPassword: 'Recuperar contraseña',
        resetButton: 'Recuperar',
        sendResetPassword: 'Cambiar contraseña',
        enterEmailAddressAssociatedWithYourAccount: 'Introduce la contraseña asociada a tu cuenta',
        emailAddress: {
          label: 'Email',
          placeholder: 'Email',
        },
        enterValidEmail: 'Por favor, introduce un email váido.',
        passwordResetEmailSent: 'Si existe una cuenta con el email proporcionado, recibirás un correo para restablecer tu contraseña.',
        passwordResetSuccess: 'Éxito! Puedes iniciar sesión con tu nueva contraseña.',
        TokenInvalidatedError: 'El enlace utilizado no es correcto. Si ha realizado varios intentos asegúrese de utilizar el enlace del último mail recibido. En caso contrario solicítelo de nuevo.',
        IllegalArgumentError: 'El enlace ha expirado',
      },
    },
    myFriends: {
      myFriends: {
        goBack: 'Volver a mis amigos',
        noSufficientPermissions: 'No tiene los suficientes permisos',
        friendCreated: 'Amigo creado correctamente',
        friendCreatedInfo: 'Se va a registrar el amigo en el sistema, en unos minutos va a poder seleccionarlo en el checkout',
        friendUpdate: 'Amigo actualizado correctamente',
        friendsNoLoaded: 'No se han podido cargar los amigos',
        friendsNoCreated: 'No se ha podido crear el amigo',
        friendsNoUpdate: 'No se ha podido actualizar el amigo',
        invalidToken: 'El enlace ha caducado o ya ha sido utilizado',
        friendRemoved: 'Amigo eliminado correctamente',
        inviteCreated: 'Amigo invitado',
        inviteNoCreated: 'No se ha podido invitar al amigo',
        search: 'Buscar por nombre o email',
        label: 'Mis amigos',
        add: 'Añadir amigo',
        edit: 'Editar amigo',
        deleteFriend: 'Eliminar amigo',
        return: 'Volver',
        saveData: 'Guardar datos',
        contactInfo: 'Información de contacto',
        guest: 'Invitado',
        registered: 'Invitado registrado',
        notInvited: 'Sin invitar',
        expired: 'Caducado',
        unknownEmail: 'Sin email',
        allStatus: 'Todos los estados',
        invited: 'Invitado',
        inviteFriend: 'Volver a invitar amigo',
        filterTextSearch: 'Filtrar',
        filterInviteStatus: 'Filtrar estado invitación',
        filterRemove: 'Borrar filtro',
        filterInviteStatusPlaceholder: 'Sin filtrar',
        notFound: 'No se encontraron amigos',
        myFriendsForm: {
          name: 'Nombre y apellidos',
          email: 'Correo electrónico',
          sendInvitation: 'Enviar invitación',
          confirmPassword: 'Confirmar Nueva Contraseña',
          password: 'Contraseña',
          invitationStatus: 'Estado invitación',
          expired: 'Caducada',
          invited: 'Invitado',
          billingData: 'Datos facturación',
          iban: 'IBAN',
          nif: 'NIF',
          yes: 'Sí',
          no: 'No',
          phoneNumber: 'Número de teléfono',
          personalMessage: 'Comentario personal a incluir en el correo de invitación',
          personalMessagePlaceholder:
            'Te adjunto invitación personal para que puedas acceder a nuestra web de amigos',
        },
        missingFriend: 'Amigo no encontrado',
        sendEmail: 'Enviar correo',
        sendEmailTooltip: 'Selecciona un amigo para acceder a esta función',
        emails: {
          form: {
            header: 'Enviar Correos',
            cc: 'CC',
            subject: 'Asunto',
            subjectPlaceholder: 'Asunto',
            submit: 'Enviar Correo',
            productSearch: 'Buscar Productos y Categorías',
            productSearchPlaceholder: 'Product or Category  Name...',
            placeholder: 'Redactar mensaje',
          },
          success: 'Email enviado correctamente',
          errors: {
            toCustomersUid: 'No se han encontrado las siguientes direcciones de correo electrónico: {{emailList}}.',
          },
        },
        ReferrerUnchangedError: 'El amigo ya existe en su lista de amigos',
        ReferrerAlreadySetError: 'El amigo ya existe en la lista de amigos de otro usuario',
        FriendNotOrphanError: 'El amigo no esta en tu lista de amigos pero ya esta registrado con otro usuario',
        ModelSavingError: 'El amigo que está intentando registrar ya se encuentra registrado',
      },
    },
    CustomVideoInfoComponent: {
      CustomVideoInfoComponent: {
        buttonText: 'VER VIDEO',
      },
    },
    user: {
      anonymousConsents: {
        preferences: 'Preferencias de Consentimiento',
        dialog: {
          title: 'Gestión de Consentimiento',
          legalDescription:
            'Usamos las cookies almacenadas en el navegador para personalizar el contenido y mejorar la experiencia de usuario. También podemos compartir los datos sobre el uso de su sitio con nuestras redes sociales. Para obtener más información, consulte nuestra política de privacidad.',
          selectAll: 'Seleccionar todo',
          clearAll: 'Borrar todo',
        },
        banner: {
          title: '',
          description:
            'Utilizamos cookies propias y de terceros para mejorar nuestros servicios, realizar análisis estadísticos sobre los hábitos de navegación de nuestros usuarios y facilitar la interacción con redes sociales. Si continúas navegando, consideramos que aceptas su uso.',
          allowAll: 'Permitir Todo',
          viewDetails: 'Ver Detalles',
        },
      },
      forgottenPassword: {
        resetPassword: 'Recuperar contraseña',
        resetButton: 'Recuperar',
        enterEmailAddressAssociatedWithYourAccount: 'Introduce la contraseña asociada a tu cuenta',
        emailAddress: {
          label: 'Usuario',
          placeholder: 'Usuario',
        },
        enterValidEmail: 'Por favor, introduce un email válido.',
        passwordResetEmailSent: 'Si existe una cuenta con el email proporcionado, recibirás un correo para restablecer tu contraseña.',
        passwordResetSuccess: 'Éxito! Puedes iniciar sesión con tu nueva contraseña.',
      },
      // deprecated
      loginForm: {
        forgotPassword: 'Recuperar contraseña',
        signIn: 'Acceder',
        register: 'Registrarse',
        dontHaveAccount: '¿No tienes una cuenta?',
        guestCheckout: 'Checkout invitado',
        access: 'Acceder',
        accept: 'Aceptar',
        chooseShipping: 'Selecciona el envío',
        formShipping: {
          homeDelivery: {
            label: 'Envío a domicilio',
            placeholder: '',
          },
          pickUpWarehouse: {
            label: 'Recoger en almacén',
            placeholder: 'Selecciona un almacén',
          },
        },
        cartModifications: 'Al cambiar de destino puede que algunos productos no estén disponibles y desaparezcan del carro',
        emailAddress: {
          label: 'Usuario',
          placeholder: 'Usuario',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        wrongEmailFormat: 'Formato de email no válido',
      },
      // deprecated
      register: {
        confirmPassword: {
          action: 'Confirma contraseña',
          label: 'Confirma contraseña',
          placeholder: 'Confirma contraseña',
        },
        managementInMyAccount: 'Gestión en Mi Cuenta.',
        termsAndConditions: 'Términos y Condiciones',
        signIn: 'Ya tengo cuenta. Iniciar sesión',
        register: 'Registrarse',
        confirmNewPassword: 'Confirmar Nueva Contraseña',
        resetPassword: 'Restablecer Contraseña',
        createAccount: 'Crear una cuenta',
        title: 'Título',
        firstName: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
        lastName: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
        emailAddress: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        newPassword: 'Nueva contraseña',
        emailMarketing:
          'Utilizar mis datos personales para recibir boletines informativos por email para campañas de marketing. Para cambiar su configuración, vaya a Gestión de Consentimiento en Mi Cuenta.',
        confirmThatRead: 'Confirmo que he leído y estoy de acuerdo con el',
        selectTitle: 'Seleccionar Título',
        passwordMinRequirements:
          'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
        bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
        titleRequired: 'Se requiere título.',
        postRegisterMessage: 'Por favor, inicie sesión con las credenciales proporcionadas.',
      },
      checkoutLogin: {
        title: 'Compra como invitado',
        info: 'Si lo deseas, podrás crear una cuenta más adelante.',
        emailAddress: {
          label: 'Correo electrónico',
          placeholder: 'Introduce un correo electrónico válido',
        },
        confirmEmail: {
          label: 'Confirma el correo electrónico',
          placeholder: 'Confirma el correo electrónico',
        },
        continue: 'Continuar',
        emailIsRequired: 'Formato de correo electrónico no válido',
        emailsMustMatch: 'No es el mismo correo electrónico',
      },
      authMessages: {
        signedOutSuccessfully: 'Ha cerrado sesión correctamente.',
      }
    },
    userAccount: {
      anonymousConsents: {
        preferences: 'Preferencias de Consentimiento',
        dialog: {
          title: 'Gestión de Consentimiento',
          legalDescription:
            'Usamos las cookies almacenadas en el navegador para personalizar el contenido y mejorar la experiencia de usuario. También podemos compartir los datos sobre el uso de su sitio con nuestras redes sociales. Para obtener más información, consulte nuestra política de privacidad.',
          selectAll: 'Seleccionar todo',
          clearAll: 'Borrar todo',
        },
        banner: {
          title: '',
          description:
            'Utilizamos cookies propias y de terceros para mejorar nuestros servicios, realizar análisis estadísticos sobre los hábitos de navegación de nuestros usuarios y facilitar la interacción con redes sociales. Si continúas navegando, consideramos que aceptas su uso.',
          allowAll: 'Permitir Todo',
          viewDetails: 'Ver Detalles',
        },
      },
      forgottenPassword: {
        resetPassword: 'Recuperar contraseña',
        resetButton: 'Recuperar',
        enterEmailAddressAssociatedWithYourAccount: 'Introduce la contraseña asociada a tu cuenta',
        emailAddress: {
          label: 'Usuario',
          placeholder: 'Usuario',
        },
        enterValidEmail: 'Por favor, introduce un email válido.',
        passwordResetEmailSent: 'Si existe una cuenta con el email proporcionado, recibirás un correo para restablecer tu contraseña.',
        passwordResetSuccess: 'Éxito! Puedes iniciar sesión con tu nueva contraseña.',
      },
      // deprecated
      loginForm: {
        forgotPassword: 'Recuperar contraseña',
        forgotPasswordLong: '¿Olvidaste tu contraseña?',
        signIn: 'Acceder',
        signInLong: 'Iniciar sesión',
        register: 'Registrarse',
        registerLong: 'Crear una cuenta',
        registerTitle: 'Nuevos clientes',
        registerInfo: 'Crear una cuenta tiene muchos beneficios: Pago más rápido, guardar más de una dirección, seguimiento de pedidos y mucho más.',
        dontHaveAccount: '¿No tienes una cuenta?',
        guestCheckout: 'Checkout invitado',
        access: 'Acceder',
        accessLong: 'Clientes registrados',
        accessInfo: 'Si tienes una cuenta, inicia sesión con tu dirección de correo electrónico.',
        accept: 'Aceptar',
        chooseShipping: 'Selecciona el envío',
        formShipping: {
          homeDelivery: {
            label: 'Envío a domicilio',
            placeholder: '',
          },
          pickUpWarehouse: {
            label: 'Recoger en almacén',
            placeholder: 'Selecciona un almacén',
          },
        },
        cartModifications: 'Al cambiar de destino puede que algunos productos no estén disponibles y desaparezcan del carro',
        emailAddress: {
          label: 'Usuario',
          labelLong: 'Correo electrónico',
          placeholder: 'Usuario',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        wrongEmailFormat: 'Formato de email no válido',
        ageStatus: {
          text: 'QUEREMOS ASEGURARNOS DE QUE NUESTRAS MARCAS SE CONSUMEN RESPONSABLEMENTE. CONFIRMA QUE ERES MAYOR DE EDAD ANTES DE ENTRAR EN NUESTRA WEB.',
          invalidAge: 'LO SENTIMOS, PERO...<br/> PARA CONTINUAR DEBES SER MAYOR DE EDAD.',
          overEighteen: 'Más de 18',
          underEighteen: 'Menos de 18'
        }
      },
      // deprecated
      register: {
        confirmPassword: {
          action: 'Confirma contraseña',
          label: 'Confirma contraseña',
          placeholder: 'Confirma contraseña',
        },
        managementInMyAccount: 'Gestión en Mi Cuenta.',
        termsAndConditions: 'Términos y Condiciones',
        signIn: 'Ya tengo cuenta. Iniciar sesión',
        register: 'Registrarse',
        confirmNewPassword: 'Confirmar Nueva Contraseña',
        resetPassword: 'Restablecer Contraseña',
        createAccount: 'Crear una cuenta',
        title: 'Título',
        firstName: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
        lastName: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
        emailAddress: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
        newPassword: 'Nueva contraseña',
        emailMarketing:
          'Utilizar mis datos personales para recibir boletines informativos por email para campañas de marketing. Para cambiar su configuración, vaya a Gestión de Consentimiento en Mi Cuenta.',
        confirmThatRead: 'Confirmo que he leído y estoy de acuerdo con el',
        selectTitle: 'Seleccionar Título',
        passwordMinRequirements:
          'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
        bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
        titleRequired: 'Se requiere título.',
        postRegisterMessage: 'Por favor, inicie sesión con las credenciales proporcionadas.',
      },
      checkoutLogin: {
        title: 'Compra como invitado',
        info: 'Si lo deseas, podrás crear una cuenta más adelante.',
        emailAddress: {
          label: 'Correo electrónico',
          placeholder: 'Introduce un correo electrónico válido',
        },
        confirmEmail: {
          label: 'Confirma el correo electrónico',
          placeholder: 'Confirma el correo electrónico',
        },
        continue: 'Continuar',
        emailIsRequired: 'Formato de correo electrónico no válido',
        emailsMustMatch: 'No es el mismo correo electrónico',
      },
      miniLogin: {
        userGreeting: 'Hola, {{name}}',
        signInRegister: 'Inicia sesión',
      },
    },
    pwa: {
      pwa: {
        addToHomeScreenDescription:
          'Añada Osborne a la pantalla de inicio de su dispositivo para volver a visitarlo más rápidamente',
        noInstallationNeeded: 'No requiere instalación',
        fastAccessToApplication: 'Acceso rápido a la aplicación',
        addToHomeScreen: 'Añadir a la pantalla de inicio',
        addedToHomeScreen: 'Se ha añadido Osborne a su pantalla de inicio',
      },
    },
    address: {
      addressForm: {
        title: 'Título',
        firstName: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
        observations: 'Observaciones de envío',
        alias: 'Alias',
        lastName: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
        address1: 'Dirección',
        address2: 'Dirección línea 2 (opcional)',
        country: 'Zona - País',
        region: 'Zona - Región',
        province: 'Provincia',
        city: {
          label: 'Ciudad',
          placeholder: 'Ciudad',
        },
        state: 'Provincia',
        zipCode: {
          label: 'Código postal',
          placeholder: 'Código postal',
        },
        phoneNumber: {
          label: 'Número de teléfono',
          placeholder: '+34 XXX XXX XXX',
          note: '(móvil, sólo para notificación de envío)',
        },
        email: {
          label: 'Dirección de Email',
          note: '(notificación de envío)',
          placeholder: 'usuario@email.com',
        },
        saveAsDefault: 'Guardar como predeterminado',
        chooseAddress: 'Elige dirección',
        streetAddress: 'Calle',
        aptSuite: 'Apt, Suite',
        selectOne: 'Seleccionar...',
        pleaseSelectOne: 'Por favor, elige una opción',
        setAsDefault: 'Guardar como predeterminado',
        titleRequired: 'Título es obligatorio.',
        userAddressAddSuccess: 'Nueva dirección añadida correctamente!',
        userAddressUpdateSuccess: 'Dirección actualizada correctamente!',
        userAddressDeleteSuccess: 'Dirección eliminada correctamente!',
        invalidAddress: 'Dirección no válida. {{message}}',
        back: 'Volver',
        deliveryNoteObservations: 'Observaciones de envío',
      },
      addressBook: {
        titleAddressBook: 'Mis direcciones',
        defaultAddressBook: 'Dirección de envío predeterminada',
        addressBooklList: 'Libreta de direcciones',
        addressBookAlias: 'Alias',
        addressBookName: 'Nombre y apellidos',
        addressBookDirection: 'Dirección',
        addressBookPhone: 'Teléfono',
        addressBookObservations: 'Observaciones de envío',
        addNewShippingAddress: 'Añade una nueva dirección de envío',
        editShippingAddress: 'Editar dirección de envío',
        areYouSureToDeleteAddress: '¿Seguro que quieres eliminar esta dirección?',
        addNewAddress: 'Añadir dirección',
        addAddress: 'Añadir dirección',
        updateAddress: 'Actualizar dirección',
        backToAddressList: 'Volver a mis direcciones',
        filter: 'Filtrar',
        filterRemove: 'Borrar filtro',
        filterNoAddresses: 'No se encontraron direcciones',
      },
      addressCard: {
        default: 'POR DEFECTO',
        selected: 'Seleccionado',
        setAsDefault: 'Establecer como predeterminado',
        shipTo: 'Dirección de envío',
        billTo: 'Dirección de facturación',
      },
    },
    asm: {
      asm: {
        mainLogoLabel: 'SAP',
        mainTitle: 'Modo de Servicio Asistido',
        logout: 'Desconectar',
        hideUi: 'Cerrar ASM',
        toggleUi: {
          collapse: 'Ocultar ASM',
          expand: 'Mostar ASM',
        },
        loginForm: {
          submit: 'Iniciar sesión',
          userId: {
            label: 'ID de Agente',
            required: 'Se requiere ID de agente',
          },
          password: {
            label: 'Contraseña',
            required: 'La contraseña es obligatoria',
          },
        },
        customerSearch: {
          searchTerm: {
            label: 'Nombre del Cliente / Email',
          },
          submit: 'Inicio de Sesión',
          noMatch: 'No se encontró ningún cliente.',
        },
        csagentTokenExpired: 'Su sesión de agente de atención al cliente ha expirado.',
        endSession: 'Finalizar Sesión',
        agentSessionTimer: {
          label: 'Tiempo de espera de la sesión',
          minutes: 'min',
          reset: 'Reiniciar',
        },
        standardSessionInProgress: 'Sesión estándar de cliente en curso.',
        auth: {
          agentLoggedInError:
            'No se puede iniciar la sesión como usuario cuando hay una sesión activa del agente CS. Por favor, emule al usuario o cierre la sesión del agente CS.',
        },
        error: {
          noCustomerId:
            'No se encontró ningún ID de cliente para el usuario seleccionado. No se puede iniciar la sesión.',
        },
      },
    },
    giftbox: {
      giftbox: {
        itemSelect: 'SELECCIONAR',
        itemSelectNotAvailable: 'NO DISPONIBLE',
        itemConfigure: 'Configura tu',
        popupAddGiftboxToCart: {
          titleCartNotEmpty: 'Tienes productos en el carrito.',
          titleOtherGiftbox: 'Tienes otra caja regalo en el carrito.',
          messageCartNotEmpty: 'Si continúas se vaciará el carrito actual.',
          messageOtherGiftbox: 'Si continúas se eliminará la caja regalo actual.',
          confirmation: '¿Deseas continuar?',
          cancel: 'Cancelar',
          continue: 'Continuar',
        },
        popupAddProductToCartWithGiftbox: {
          title: 'Tienes una caja regalo en el carrito.',
          message: '¿Qué deseas hacer?',
          cancel: 'Ver caja regalo',
          continue: 'Eliminar caja regalo',
        },
        header: {
          btnDeleteCart: 'Cancelar caja regalo',
        },
        cartTooltip: {
          price: 'Precio',
        },
      },
    },
    cart: {
      cartDetails: {
        id: 'ID',
        proceedToCheckout: 'Tramitar pedido',
        cartName: 'Carrito de compras',
        continueShopping: 'Continuar comprando',
        emptyCart: 'Vaciar carrito',
        missingInformation: 'Ha ocurrido un error al procesar su pedido. Por favor inténtelo de nuevo pasados unos minutos',
        checkoutShippingAddress: 'No se ha seleccionado una dirección de envío válida.',
        checkoutDeliveryAddress: 'No se ha seleccionado una dirección de envío válida.',
        checkoutPaymentType: 'No se ha seleccionado un método de pago válido.',
        checkoutDeliveryMode: 'No se ha seleccionado un método de envío válido.',
        remainingSlots: 'Te queda {{count}} espacio para completar tu caja regalo.',
        remainingSlots_plural: 'Te quedan {{count}} espacios para completar tu caja regalo.',
        remainingSlotsToOrder: 'Te queda {{count}} espacio para poder pedir tu caja regalo.',
        remainingSlotsToOrder_plural: 'Te quedan {{count}} espacios para poder pedir tu caja regalo.',
        checkout: 'Pago cancelado',
        checkoutPaymentDetails: 'Pago cancelado',
        deliveryMessage: 'Este pedido contiene productos de distintos almacenes y la entrega será realizada en envíos distintos.',
      },
      cartItems: {
        id: 'Código',
        description: 'Descripción',
        item: 'Artículo',
        itemPrice: 'Precio',
        quantity: 'Cantidad',
        quantityTitle: 'La cantidad representa el número total de este artículo en el carrito.',
        total: 'Total',
        cartTotal: 'Total ({{count}} artículo)',
        cartTotal_plural: 'Total ({{count}} artículos)',
        deliveryNumber: 'Envío {{count}}',
      },
      orderCost: {
        orderSummary: 'Resumen',
        subtotal: 'Subtotal:',
        shipping: 'Envío:',
        giftCard: 'Tarjeta personalizada:',
        estimatedShipping: 'Envío:',
        discount: 'Descuento:',
        salesTax: 'Impuestos:',
        grossTax: 'No incluye impuestos de:',
        grossIncludeTax: 'Impuestos Incl.:',
        total: 'Total:',
        toBeDetermined: 'Por determinar',
      },
      voucher: {
        coupon: 'Aplicar código promocional',
        coupon_plural: 'Cupones',
        apply: 'Aplicar descuento',
        placeholder: 'Introduce el código',
        applyVoucherSuccess: '{{voucherCode}} se ha aplicado.',
        removeVoucherSuccess: '{{voucherCode}} se ha quitado.',
        anchorLabel: 'Introduce o quita un cupón',
        vouchersApplied: 'Cupones aplicados',
        availableCoupons: 'Cupones disponibles',
        availableCouponsLabel: 'Puedes añadir estos cupones a este pedido.',
      },
      saveForLaterItems: {
        itemTotal: '({{count}} artículo guardado)',
        itemTotal_plural: '({{count}} artículos guardados)',
        cartTitle: 'Carrito',
        saveForLater: 'Guardar para más tarde',
        moveToCart: 'Mover al carrito',
        stock: 'Disponibilidad',
        forceInStock: 'Disponible',
      },
    },
    checkoutShipping: {
      checkoutShipping: {
        shippingMethod: 'Método de envío',
        chooseShippingMethod: 'Selecciona un envío',
        deliveryNote: {
          name: 'Albarán valorado',
          yes: 'Sí',
          no: 'No',
        },
        deliveryNoteObservations: 'Observaciones de envío',
        observationPayment: 'Observaciones para la factura',
        observationsDeliveryPlaceholder: 'Puedes añadir indicaciones al repartidor o una nota para el destinatario',
        presentCard: 'Tarjeta personalizada',
        presentCardPlaceholder: 'Mensaje',
        standardDelivery: 'Envío normal',
        premiumDelivery: 'Envío urgente',
      }
    },
    checkout: {
      checkoutAddress: {
        shippingAddress: '01. Dirección de envío',
        selectYourShippingAddress: 'Selecciona la dirección de envío',
        defaultShippingAddress: 'Dirección de envío predeterminada',
        verifyYourAddress: 'Comprueba la dirección',
        ensureAccuracySuggestChange:
          'Para asegurar una entrega precisa, sugerimos el siguiente cambio.',
        chooseAddressToUse:
          'por favor, elige la dirección que deseas usar:',
        suggestedAddress: 'Dirección sugerida',
        enteredAddress: 'Dirección introducida',
        addNewAddress: 'Nueva dirección',
        shipToThisAddress: 'Enviar a esta dirección',
        editAddress: 'Editar dirección',
        saveAddress: 'Guardar dirección',
        addressList: 'Mi libreta de direcciones',
        showAddressList: 'Ver mis direcciones',
        hideAddressList: 'Ocultar mis direcciones',
        filter: 'Filtrar',
        filterRemove: 'Borrar filtro',
        filterNoAddresses: 'No se encontraron direcciones',
        billing: {
          title: 'DIRECCIÓN DE FACTURACIÓN',
          label: '¿Solicitar factura completa?',
        },
        onBehalfOf: {
          label: 'Facturar a ti o a un amigo',
          me: 'A mí',
          friend: 'A un Amigo',
          placeholder: 'Escribe el nombre de un amigo',
          deliveryAddress: 'Dirección de envío seleccionada',
          addressListTitle: 'Dirección de envío por defecto de {{friendName}}',
          table: {
            alias: 'Alias',
            name: 'Nombre',
            phone: 'Teléfono',
            address: 'Dirección',
            deliveryNote: 'Observaciones',
          },
        },
      },
      checkoutOrderConfirmation: {
        confirmationOfOrder: 'Confirmación del Pedido:',
        thankYou: 'Gracias por tu pedido!',
        thankYou2: 'Nuestro equipo de logística ya está manos a la obra para que llegue lo antes posible y en perfectas condiciones.',
        thankYou31: 'Puedes ver esta orden de compra y descargar la factura, desde',
        thankYou32: 'dentro del icono MI PERFIL.',
        bills: 'MIS FACTURAS',
        orderDetails: 'Puedes modificar o seguir tu pedido',
        orderDetailsLink: 'AQUÍ',
        follow: 'Síguenos en:',
        invoiceHasBeenSentByEmail: 'Recibirás la factura por email en breve.',
        orderItems: 'Productos',
        orderPlacedSuccessfully: 'Pedido realizado con éxito',
        createAccount: 'Crea una cuenta',
        createAccountForNext:
          'Crea una cuenta con <{{email}}> para un checkout más rápido en tu próxima visita.',
        createAccountSuccess: 'Tu cuenta se ha creado correctamente.',
        replenishmentNumber: 'Replenishment #',
        placedOn: 'Fecha',
        status: 'Estado',
        active: 'Activo',
        cancelled: 'Cancelado',
        frequency: 'Frecuencia',
        nextOrderDate: 'Fecha del próximo pedido',
        orderNumber: 'Número de pedido',
      },
      checkoutReview: {
        review: 'Resumen',
        orderItems: 'Productos',
        autoReplenishOrder: 'Auto Replenish Order',
        confirmThatRead: 'Acepto los',
        placeOrder: 'Realizar pedido',
        scheduleReplenishmentOrder: 'Programar pedido de reabastecimiento',
        orderType_PLACE_ORDER: 'Ninguno (sólo un pedido)',
        orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Orden de Reabastecimiento',
        termsAndConditions: 'Términos y condiciones',
        editShippingAddress: 'Editar dirección de envío',
        poNumber: 'Número de Pedido',
        every: 'Cada',
        startOn: 'Comenzar',
        dayOfMonth: 'En el día',
        repeatOnDays: 'Repetir en los días siguientes',
        recurrencePeriodType_DAILY: 'Día(s)',
        recurrencePeriodType_WEEKLY: 'Semana(s)',
        recurrencePeriodType_MONTHLY: 'Mes',
        products: 'Productos',
        group: 'Envío {{current}} de {{total}}',
      },
      checkout: {
        backToCart: 'Volver al carrito',
        invalid: {
          accountType: 'Tu cuenta no te permite checkout aquí.',
        },
      },
      checkoutPO: {
        noPoNumber: 'Ninguno',
        poNumber: 'Número de pedido (opcional)',
        costCenter: 'Centro de Costes',
        placeholder: 'Introduzca el P.O.',
        availableLabel:
          'Las direcciones de envío disponibles dependen de la unidad del centro de costes',
      },
      checkoutProgress: {
        missingPaymentMethod: 'Seleccione un método de pago válido',
        missingPaymentMethodOptions: 'Ha ocurrido un error al obtener los métodos de pagos. Por favor contacte a su administrador',
        methodOfPayment: '03. Método de pago',
        shippingAddress: '01. Dirección de envío',
        deliveryMode: '02. Método de envío',
        paymentDetails: '03. Detalles del pago',
        reviewOrder: '04. Resumen',
      },
      simulate: {
        CartError: 'Error al simular la orden',
      },
    },
    paypal: {
      paypal: {
        error: {
          default: 'Ha habido un error con PayPal. Por favor, inténtelo de nuevo más tarde.',
          errorDetail: 'No se ha podido completar la transacción. {{msg}}',
        },
      },
    },
    bills: {
      bills: {
        title: 'Mis facturas',
        filterRemove: 'Borrar filtro',
        filterInvoiceNumber: 'Filtrar nº factura',
        filterDateType: 'Filtrar',
        filterDateFrom: 'desde',
        filterDateTo: 'hasta',
        filterInvoicesFriends: 'Mostrar facturas de mis amigos',
        headerInvoiceCode: 'Nº factura',
        headerInvoiceUser: 'Facturado a',
        headerInvoiceDate: 'Fecha factura',
        headerInvoiceDueDate: 'Fecha vencimiento',
        headerInvoicePayment: 'Forma pago',
        headerInvoiceAmount: 'Importe',
        headerInvoicePending: 'Pendiente',
        notFound: 'No se encontraron facturas',
        sortByTitle: 'Ordenar por',
      },
    },
    contactForm: {
      contactForm: {
        labels: {
          email: 'Email',
          message: 'Comentario sobre el caso',
          subject: 'Asunto',
          orderCode: 'Número de pedido',
          accept: 'Acepto el tratamiento de los datos personales para las actividades que a continuación se indican.',
          requiredAction: 'Acción requerida',
          productsAffected: {
            label: 'Productos y cantidades afectadas',
            productDescription: 'Descripción del producto',
            quantity: 'Cantidad',
            add: 'PRODUCTO',
            remove: 'Quitar producto',
          },
          qualityProblem: 'Problema de calidad',
          qualityProblems: {
            excessFat: 'Exceso de grasa',
            smellFlavorColorProblems: 'Problemas de olor/sabor/color',
            vacuumLoss: 'Pérdida de vacío',
            excessLackCuring: 'Exceso/falta de curación',
            other: 'Otros motivos',
          },
        },
        placeholders: {
          ticketCauses: 'Selecciona un motivo',
          ticketTemplates: 'Selecciona una opción',
          qualityProblems: 'Selecciona un problema',
        },
        formTemplateTitle: 'Formulario de contacto para {{formTitle}}',
        submit: 'Enviar',
        updateSuccess: 'Solicitud enviada correctamente',
        updateError: 'Ha habido un error al intentar enviar la solicitud. Por favor, inténtelo de nuevo más tarde.',
        modelNotFoundError: 'No coincide el email para el número de pedido. Por favor, inténtelo de nuevo tras corregir los datos.',
      },
    },
    myAccount: {
      orderDetails: {
        orderId: 'Pedido #',
        replenishmentId: 'Reabastecimiento #',
        purchaseOrderId: 'Pedido de Compra #',
        emptyPurchaseOrderId: 'Ninguno',
        none: 'Ninguno {{value}}',
        placed: 'Colocado',
        placedBy: 'Colocado por',
        unit: 'Unidad',
        costCenter: 'Centro de Costes',
        costCenterAndUnit: 'Centro de Costes / Unidad',
        costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
        payByAccount: 'Pagar con cuenta',
        paidByCreditCard: '(pagado con tarjeta de crédito)',
        status: 'Estado',
        shippedOn: 'Enviado en',
        startOn: 'Comenzar',
        nextOrderDate: 'Next Order Date',
        frequency: 'Frequencia',
        cancelled: ' Cancelado',
        shipping: 'Envío',
        deliveryStatus_CREATED: 'Creado',
        deliveryStatus_IN_TRANSIT: 'En transito',
        deliveryStatus_READY_FOR_PICKUP: 'Listo para recoger',
        deliveryStatus_READY_FOR_SHIPPING: 'Listo para enviar',
        deliveryStatus_WAITING: 'Esperando',
        deliveryStatus_DELIVERING: 'Entregando',
        deliveryStatus_PICKPACK: 'Preparación para el envío',
        deliveryStatus_PICKUP_COMPLETE: 'Recogida Completa',
        deliveryStatus_DELIVERY_COMPLETED: 'Entrega Completa',
        deliveryStatus_PAYMENT_NOT_CAPTURED: 'Problema de Pago',
        deliveryStatus_READY: 'En Proceso',
        deliveryStatus_DELIVERY_REJECTED: 'Entrega Rechazada',
        deliveryStatus_SHIPPED: 'Shipped',
        deliveryStatus_TAX_NOT_COMMITTED: 'Problema Fiscal',
        deliveryStatus_CANCELLED: 'Cancelado',
        statusDisplay_: '',
        statusDisplay_cancelled: 'Cancelado',
        statusDisplay_cancelling: 'Cancelación Pendiente',
        statusDisplay_completed: 'Entregado',
        statusDisplay_created: 'Creado',
        statusDisplay_error: 'Pendiente',
        statusDisplay_Error: 'Pendiente',
        statusDisplay_processing: 'Pendiente',
        statusDisplay_open: 'Abrir',
        statusDisplay_pending: {
          approval: 'Pendiente de aprobación',
          merchant: {
            approval: 'Pendiente de aprobación del comerciante',
          },
        },
        statusDisplay_approved: 'Aprobado',
        statusDisplay_rejected: 'Rechazado',
        statusDisplay_merchant: {
          approved: 'Comerciante Aprobado',
          rejected: 'Comerciante Rechazadp',
        },
        statusDisplay_assigned: {
          admin: 'Asignado al administrador',
        },
        consignmentTracking: {
          action: 'Paquete de seguimiento',
          dialog: {
            header: 'Informacón de Seguimiento',
            shipped: 'Enviado',
            estimate: 'Entrega Estimada',
            carrier: 'Servicio de entrega',
            trackingId: 'Número de Seguimiento',
            noTracking:
              'El paquete no ha salido del almacén. La información de seguimiento estará disponible después de que se envíe el paquete.',
            loadingHeader: 'Seguimiento del envío',
          },
          button: {
            label: 'Seguimiento de envío',
          },
          deliveryDate: 'Fecha de entrega',
          estimatedDeliveryDate: 'Fecha de entrega estimada',
          consignment: 'Nº albarán',
          reference: 'Referencia Interna',
          failure: 'Incidencia',
        },
        cancellationAndReturn: {
          returnAction: 'Solicitar una Devolución',
          cancelAction: 'Cancelar Artículos',
          item: 'Artículo',
          itemPrice: 'Precio del Articulo',
          quantity: 'Cantidad',
          returnQty: 'Cantidad a devolver',
          cancelQty: 'Cantidad a cancelar',
          setAll: 'Devolver todas las unidades',
          totalPrice: 'Total',
          submit: 'Enviar Solicitud',
          returnSuccess: 'Su solicitud de devolución ({{rma}}) se presentó',
          returnError: 'Ha ocurrido un error al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.',
          returnPackError: 'No se ha podido procesar la solicitud. Cantidades del pack incorrectas.',
          cancelSuccess:
            'Su solicitud de cancelación fue enviada (el pedido original {{orderCode}} será actualizado',
          hasPackGroup: 'Los productos que son parte de un pack no pueden devolverse por separado',
        },
        cancelReplenishment: {
          title: 'Cancelar Reabastecimiento',
          description: '¿Cancelar cualquier pedido de reabastecimiento futura?',
          accept: 'Sí',
          reject: 'No',
          cancelSuccess:
            'Pedido de reabastecimiento #{{replenishmentOrderCode}} ha sido cancelado con éxito',
        },
        actions: {
          contentCancellation: '¿Seguro que deseas cancelar el pedido? Se procederá al abono en su cuenta durante los siguientes días en caso de haber pagado con Tarjeta Bancaria',
          contentRestoration: 'Para modificar el pedido se procederá a la cancelación del actual. Se procederá al abono de su cuenta durante los siguientes días en caso  haber pagado con Tarjeta Bancaria. Automáticamente se cargará todo el pedido anterior para que pueda modificarlo. ¿Está de acuerdo?',
          contentRestoreOnly: '¿Seguro que deseas realizar este pedido de nuevo?',
          cancellable: 'Cancelar pedido',
          restoration: 'Modificar pedido',
          restoreOnly: 'Volver a pedir',
          accept: 'Aceptar',
          cancel: 'Cancelar',
          back: 'Volver',
          orderCancellationSuccess: 'Orden {{orderCode}} cancelada correctamente',
          orderCancellationFail: 'Ha ocurrido un error al intentar cancelar la orden {{orderCode}}',
          orderRestorationSuccess: 'Orden {{orderCode}} restaurada correctamente',
          orderRestorationFail: 'Ha ocurrido un error al intentar restaurar la orden {{orderCode}}',
          orderRestoreOnlySuccess: 'Productos de la orden {{orderCode}} añadidos al carrito correctamente',
          orderRestoreOnlyFail: 'Ha ocurrido un error al intentar volver a pedir la orden {{orderCode}}',
          ticket: 'Factura simplificada',
        },
        orderNumber: 'Número de pedido',
        shippingMethod: 'Método de envío'
      },
      orderHistory: {
        orderHistory: 'Mis pedidos',
        orderId: 'Nº pedido',
        emptyPurchaseOrderId: 'Ninguna',
        date: 'Fecha creación',
        status: 'Estado',
        total: 'Importe',
        noOrders: 'No se encontraron pedidos',
        noReplenishmentOrders:
          'No se encontraron pedidos de reabastecimiento',
        startShopping: 'Continuar comprando',
        sortBy: 'Ordenar por',
        sortByTitle: 'Ordenar por',
        sortByMostRecent: 'Ordenar por más reciente',
        sortOrders: 'Ordenar',
        replenishmentOrderHistory: 'Historial de Pedidos de Reabastecimiento',
        replenishmentOrderId: 'Reabastecimiento #',
        purchaseOrderNumber: 'PO #',
        startOn: 'Comenzar',
        frequency: 'Frecuencia',
        nextOrderDate: 'Próxima Fecha de Pedido',
        cancel: 'Cancelar',
        cancelled: 'Cancelado',
        replenishmentHistory: 'Historial de Reabastecimiento',
        notFound: 'No se encontraron pedidos',
        cancelOrder: 'Cancelar',
        editOrder: 'Modificar',
        restoreOnlyOrder: 'Volver a pedir',
        orderDetails: 'Detalles del pedido',
        invoicedTo: 'Facturado a',
        sendTo: 'Enviado a',
        filterRemove: 'Borrar filtro',
        filterOrderNumber: 'Filtrar nº pedido',
        filterDateFrom: 'Filtrar fecha desde',
        filterDateTo: 'hasta',
        filterOrdersInvoiced: 'Mostrar pedidos facturados',
        filterOrdersFriends: 'Mostrar pedidos de mis amigos',
        actions: {
          contentCancellation: '¿Seguro que deseas cancelar el pedido? Se procederá al abono en su cuenta durante los siguientes días en caso de haber pagado con Tarjeta Bancaria',
          contentRestoration: 'Para modificar el pedido se procederá a la cancelación del actual. se procederá al abono de su cuenta durante los siguientes días en caso  haber pagado con Tarjeta Bancaria. Automáticamente se cargará todo el pedido anterior para que pueda modificarlo. ¿Está de acuerdo?',
          cancellable: 'Cancelar pedido',
          restoration: 'Modificar Pedido',
          accept: 'Aceptar',
          cancel: 'Cancelar',
          back: 'Volver',
        },
      },
      closeAccount: {
        confirmAccountClosure: 'Confirmar el Cierre de la Cuenta',
        confirmAccountClosureMessage:
          '¿Estás seguro/a de que quieres cerrar tu cuenta?',
        closeMyAccount: 'CERRAR MI CUENTA',
        accountClosedSuccessfully: 'Cuenta cerrada con éxito',
        accountClosedFailure: 'No se pudo cerrar la cuenta',
      },

      // @deprecated the updateEmailForm labels are moved to the user lib and will be dropped with the next major release
      updateEmailForm: {
        newEmailAddress: {
          label: 'Nuevo email',
          placeholder: 'Introduzca email',
        },
        confirmNewEmailAddress: {
          label: 'Confirmar nuevo email',
          placeholder: 'Introduzca email',
        },
        enterValidEmail: 'Por favor, introduzca un email válido.',
        bothEmailMustMatch: 'Ambos emails deben coincidir',
        password: {
          label: 'Contraseña',
          placeholder: 'Introduzca contraseña',
        },
        pleaseInputPassword: 'Por favor, introduzca la contraseña',
        emailUpdateSuccess: 'Éxito. Inicia sesión con {{ newUid }}',
      },

      updatePasswordForm: {
        changePassword: 'Cambiar contraseña',
        oldPassword: {
          label: 'Contraseña actual',
          placeholder: 'Contraseña actual',
        },
        oldPasswordIsRequired: 'Old password is required.',
        newPassword: {
          label: 'Nueva Contraseña',
          placeholder: 'Nueva Contraseña',
        },
        passwordMinRequirements:
          'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
        confirmPassword: {
          label: 'Confirmar Nueva Contraseña',
          placeholder: 'Confirmar Contraseña',
        },
        bothPasswordMustMatch: 'Ambos contraseñas deben coincidir',
        passwordUpdateSuccess: 'Contraseña actualizada con éxito',
        sendForm: 'Cambiar contraseña',
      },
      updateProfileForm: {
        pageTitle: 'Mi información',
        contactInfo: 'INFORMACIÓN DEL CONTACTO',
        addressInfo: 'DIRECCIÓN DE FACTURACIÓN',
        title: '',
        none: '',
        firstName: {
          label: 'Nombre y apellidos',
          placeholder: 'Nombre y apellidos',
        },
        firstNameIsRequired: 'Se requiere el nombre.',
        lastName: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
        lastNameIsRequired: 'Se requieren los apellidos.',
        profileUpdateSuccess: 'Datos personales actualizados con éxito',
        customerId: 'Cliente #',
        documentIdentifier: 'NIF',
        bankAccountNumber: 'IBAN',
        phone: {
          label: 'Número de teléfono',
          info: '(móvil, sólo para notificación de envío)',
        },
        uid: 'Correo electrónico',
        address: 'Dirección',
        town: 'Ciudad',
        postalCode: 'Código postal',
        state: 'Provincia',
        country: 'Zona - País',
        region: 'Zona - Región',
        save: 'Guardar datos',
        selectOne: 'Seleccionar...',
      },
      consentManagementForm: {
        clearAll: 'Borrar todo',
        selectAll: 'Seleccionar todo',
        message: {
          success: {
            given: 'Consentimiento aceptado con éxito.',
            withdrawn: 'Consentimiento rechazado con éxito.',
          },
        },
      },
      myCoupons: {
        noCouponsMessage: 'No tienes cupones disponibles.',
        effectiveTitle: 'Efectivo:',
        Effective: 'EFFECTIVO',
        PreSession: 'EFECTIVO PRONTO',
        ExpireSoon: 'EXPIRARÁ PRONTO',
        readMore: 'Leer más',
        notification: 'Notificación',
        findProducts: 'Buscar Productos',
        status: 'Estados:',
        dialogTitle: 'Cupón',
        claimCustomerCoupon: 'Has reclamado correctamente este cupón.',
        myCoupons: 'Mis cupones',
        startDateAsc: 'Fecha de Inicio (ascendente)',
        startDateDesc: 'Fecha de Inicio (descendente)',
        endDateAsc: 'Fecha de Finalización (ascendente)',
        endDateDesc: 'Fecha de Finalización (descendente)',
        sortByMostRecent: 'Ordenar por más reciente',
        notesPreffix:
          'Puede establecer sus canales preferidos para recibir notificaciones de cupones en el ',
        notesLink: 'Canales de Notificación',
        notesSuffix: ' página.',
      },
      notificationPreference: {
        title: 'NOTIFICACIONES',
        message: 'Seleccione sus canales de notificación preferidos:',
        note: 'Nota: ',
        noteMessage:
          'Si desactiva todos los canales, no podrá recibir más notificaciones.',
        EMAIL: 'Correo electrónico:',
        SMS: 'SMS:',
        SITE_MESSAGE: 'Mensaje en la web',
      },
      myInterests: {
        header: 'MIS INTERESES',
        item: 'ARTICULO',
        price: 'PRECIO',
        notifications: 'NOTIFICACIONES',
        noInterests: 'Aún no tienes intereses registrados.',
        inStock: 'Disponible',
        lowStock: 'Pocas existencias',
        outOfStock: 'No disponible',
        BACK_IN_STOCK: 'De nuevo en stock',
        sortByMostRecent: 'Ordenar por más reciente',
        expirationDate: ' - Hasta {{ expirationDate }}',
        productId: 'ID {{ code }}',
        remove: 'ELIMINAR',
        sorting: {
          byNameAsc: 'Nombre (ascendente)',
          byNameDesc: 'Nombre (descendente)',
        },
      },
      AccountOrderHistoryTabContainer: {
        tabs: {
          AccountOrderHistoryComponent: 'TODOS LOS PEDIDOS ({{param}})',
          OrderReturnRequestListComponent: 'DEVOLUCIONES ({{param}})',
        },
      },
      returnRequestList: {
        pageTitle: 'Mis devoluciones',
        returnRequestId: 'Nº devolución',
        orderId: 'Nº pedido',
        orderReturnId: 'Nº pedido de devolución',
        date: 'Fecha creación',
        status: 'Estado',
        total: 'Importe',
        sortByTitle: 'Ordenar por',
        sortByMostRecent: 'Ordernar por más reciente',
        statusDisplay: '-',
        statusDisplay_APPROVAL_PENDING: 'Aprobación pendiente',
        statusDisplay_CANCELED: 'Cancelada',
        statusDisplay_CANCELLING: 'Cancelando',
        statusDisplay_WAIT: 'Esperando',
        statusDisplay_RECEIVED: 'Recibida',
        statusDisplay_RECEIVING: 'Recibiendo',
        statusDisplay_APPROVING: 'Aprobada',
        statusDisplay_REVERSING_PAYMENT: 'Anulación del Pago',
        statusDisplay_PAYMENT_REVERSED: 'Pago Anulado',
        statusDisplay_PAYMENT_REVERSAL_FAILED: 'Anulación de Pago Fallida',
        statusDisplay_REVERSING_TAX: 'Anulación de Impuestos',
        statusDisplay_TAX_REVERSED: 'Impuesto Anulado',
        statusDisplay_TAX_REVERSAL_FAILED: 'Anulación de Impuestos Fallida',
        statusDisplay_COMPLETED: 'Completada',
        noReturns: 'No has realizado ninguna devolución',
        startShopping: 'Continuar comprando',
      },
      returnRequest: {
        pageTitle: 'Solicitud de devolución',
        returnRequestId: 'Nº solicitud',
        orderCode: 'Nº pedido',
        orderReturnId: 'Nº pedido de devolución',
        status: 'Estado',
        cancel: 'Cancelar Solicitud de Devolución',
        item: 'Descripción',
        itemPrice: 'Precio del Articulo',
        returnQty: 'Cantidad devuelta',
        total: 'Total',
        summary: 'Resumen',
        subtotal: 'Subtotal',
        deliveryCode: 'Gastos de envío',
        estimatedRefund: 'Reembolso',
        note: 'Los totales son estimados y pueden no incluir impuestos u otros cargos.',
        cancelSuccess: 'Su solicitud de devolución ({{rma}}) fue cancelada',
      },
    },
    wishlist: {
      wishlist: {
        empty: 'Aún no hay productos en tu lista de deseos',
        title: 'Lista de deseos',
        remove: 'Eliminar de la lista de deseos',
      }
    },
    payment: {
      paymentForm: {
        payment: 'Método de pago',
        choosePaymentMethod: 'Elija un método de pago',
        paymentType: 'Tipo de pago',
        paymentObservations: 'Observaciones para la factura',
        accountHolderName: {
          label: 'Nombre del titular de la cuenta',
          placeholder: 'Nombre del titular de la cuenta',
        },
        cardNumber: 'Número de Tarjeta',
        expirationDate: 'Fecha de Caducidad',
        securityCode: 'Código de Seguridad (CVV)',
        securityCodeTitle: 'Valor de verificación de la tarjeta',
        saveAsDefault: 'Guardar como predeterminado',
        setAsDefault: 'Establecer como predeterminado',
        billingAddress: 'Dirección de facturación',
        sameAsShippingAddress: 'Igual que la dirección de envío',
        selectOne: 'Seleccionar...',
        monthMask: 'MM',
        yearMask: 'YYYY',
        useThisPayment: 'Utilizar este pago',
        addNewPayment: 'Añadir Nuevo Pago',
        changePayment: 'Cambiar Pago',
        documentIdentifier: 'NIF',
      },
      paymentMethods: {
        paymentMethods: 'Métodos de pago',
        newPaymentMethodsAreAddedDuringCheckout:
          'Los nuevos métodos de pago se añaden durante el proceso de compra.',
        invalidField: 'Campo no válido: {{ field }}',
      },
      paymentCard: {
        deleteConfirmation:
          '¿Está seguro de que quiere eliminar este método de pago?',
        setAsDefault: 'Establecer como predeterminado',
        expires: 'Expira: {{ month }}/{{ year }}',
        defaultPaymentMethod: '✓ PREDETERMINADO',
        selected: 'Seleccionado',
      },
      paymentTypes: {
        title: 'Método de pago',
        paymentType: '{{ display }}',
        paymentType_BankAccount: '{{ display }} de {{ name }}',
        saveCreditCard: 'Guardar tarjeta de crédito',
        creditCard: 'Tarjeta Bancaria {{cardNumber}}',
        creditCardDeletePopup: {
          buttonDelete: 'Eliminar',
          buttonCancel: 'Volver',
          message: '¿Seguro que desea eliminar la tarjeta bancaria número {{cardId}}?',
          accountHolderName: 'Nombre: {{accountHolderName}}',
          cardNumber: 'Número: {{cardNumber}}',
        },
        missingAddress: 'Ha ocurrido un error al validar la dirección de entrega. Selecciona una nueva dirección de entrega para continuar.',
      },
    },
    product: {
      productDetails: {
        id: 'ID',
        quantity: 'Cantidad',
        productDetails: 'Detalles del producto',
        specification: 'Especificaciones',
        reviews: 'Reseñas',
        shipping: 'Envío',
        share: 'Compartir',
        showReviews: 'Mostrar reseñas',
        noReviews: 'No hay valoraciones',
        slots: '{{ count }} espacio',
        slots_plural: '{{ count }} espacios',
        discount: 'Dto.',
      },
      productList: {
        filterBy: {
          label: 'Filtrar por',
          action: 'Filtrar por',
        },
        appliedFilter: 'Filtros aplicados:',
        showLess: 'Mostrar menos...',
        showMore: 'Mostrar más...',
        sortByRelevance: 'Ordenar por Relevancia',
        backToTopBtn: 'VOLVER ARRIBA',
        showMoreBtn: 'MOSTRAR MÁS',
        sortResults: 'Ordenar resultados',
      },
      productFacetNavigation: {
        filterBy: {
          label: 'Filtrar por',
          action: 'Filtrar por',
        },
        appliedFilter: 'Filtros aplicados:',
        showLess: 'Mostrar menos...',
        showMore: 'Mostrar más...',
        sortByRelevance: 'Ordenar por Relevancia',
      },
      productSummary: {
        id: 'ID',
        showReviews: 'Mostrar reseñas',
        share: 'Compartir',
      },
      productReview: {
        overallRating: 'Valoración global',
        reviewTitle: 'Añade un título',
        writeYourComments: 'Añadir una reseña escrita',
        rating: 'Valoración',
        reviewerName: 'Nombre (opcional)',
        writeReview: 'Valorar este producto',
        more: 'Más',
        less: 'Menos',
        thankYouForReview:
          'Gracias por tu opinión! Se aprobará antes de aparecer aquí.',
      },
      addToCart: {
        itemsNotAddedToYourCart: 'No quedan unidades suficientes del artículo para añadir a tu carrito',
        itemsAddedToYourCart: 'Se han añadido artículos a tu carrito',
        itemsIncrementedInYourCart:
          'Este artículo ya estaba en tu carrito. La cantidad se ha actualizado.',
        items: 'Artículos',
        updatingCart: 'Actualizando carrito...',
        addToCart: 'Comprar',
        viewCart: 'ver carrito',
        proceedToCheckout: 'ir al checkout',
        quantity: 'Cantidad',
        outOfStock: 'NO DISPONIBLE',
        inStock: 'Disponible',
        selectStyleAndSize: 'Selecciona estilo y tamaño para comprobar el stock',
        eachUnit: 'c/ud.',
        quantityUnit: 'Cantidad: {{qty}} ({{name}}{{measurementToSalesUnit}})',
        quantityUnit_less: 'Cantidad: {{qty}}',
        continueShopping: 'Seguir Comprando',
        outOfStockUnit: 'NO DISPONIBLE',
        unitSelect: 'SELECCIONAR',
      },
      TabPanelContainer: {
        tabs: {
          ProductDetailsTabComponent: 'Detalles',
          ProductSpecsTabComponent: 'Especificaciones',
          ProductReviewsTabComponent: 'Valoraciones',
          deliveryTab: 'Envio',
        },
      },
      /**
       * @deprecated since 3.2
       * Use feature-library @spartacus/product/variants/assets instead
       */
      variant: {
        style: 'Estilo',
        selectedStyle: 'Estilo Seleccionado',
        size: 'Tamaño',
        color: 'Color',
        sizeGuideLabel: 'Guía de estilo',
      },
      addToWishList: {
        add: 'Añadir a la lista de deseos',
        remove: 'Eliminar de la lista de deseos',
        anonymous: 'Inicia sesión para añadir a la lista de deseos',
      },
      stockNotification: {
        notifyMe: 'NOTIFÍCAME',
        stopNotify: 'CANCELAR NOTIFICACIÓN',
        getNotify: 'Recibe una notificación cuando este producto esté disponible.',
        getNotifySuffix: 'para recibir una notificación cuando este producto esté disponible.',
        activateChannelsPrefix: 'Para recibir notificaciones debes activar los ',
        channelsLink: 'Canales de Notificación',
        activateChannelsSuffix: '.',
        notified: 'Serás notificado cuando este producto esté disponible de nuevo.',
        getNotified: 'Recibe una notificación cuando este producto esté disponible de nuevo.',
        unsubscribeSuccess: 'Ya no recibirás una notificación de disponibilidad para este producto.',
        subscriptionDialog: {
          header: 'Suscripción fuera de stock',
          notifiedPrefix: 'Se le notificará en:',
          notifiedSuffix: 'tan pronto como este producto vuelva a estar disponible.',
          manageChannelsPrefix: '',
          manageChannelsLink: '',
          manageChannelsSuffix: '',
          manageSubscriptionsPrefix: '',
          manageSubscriptionsLink: '',
          manageSubscriptionsSuffix: '',
          okBtn: 'OK',
          subscribing: 'Suscripción a las notificaciones de disponiblidad de existencias de este producto',
        },
      },
      itemCounter: {
        removeOne: 'Quitar uno',
        addOneMore: 'Añade uno más',
        quantity: 'Cantidad',
      },
    },
    productImageZoomTrigger: {
      productImageZoomTrigger: {
        expand: 'Hacer Zoom',
        close: 'Close',
      },
    },
    productImageZoomDialog: {
      productImageZoomDialog: {
        expand: 'Hacer Zoom',
        close: 'Close',
      },
    },
    statistics: {
      statistics: {
        compare: {
          label: 'Comparación con el año anterior',
          yes: 'Si',
          no: 'No',
        },
        label: 'Estadísticas',
        month: 'Meses',
        years: 'Año',
        euros: 'Euros',
        table: {
          article: 'Artículo',
          january: 'Ene.',
          february: 'Feb.',
          march: 'Mar.',
          april: 'Abr.',
          may: 'May.',
          june: 'Jun.',
          july: 'Jul.',
          august: 'Ago.',
          september: 'Sep.',
          october: 'Oct.',
          november: 'Nov.',
          december: 'Dic.',
        },
      },
    },
    newsletter: {
      newsletter: {
        email: {
          submit: 'SUSCRÍBETE',
          submitSuccess: '¡SUSCRITO!',
          placeholder: 'Introduce tu dirección de correo electrónico',
        },
        accept: {
          label: 'Acepto el tratamiento de mis datos personales para las actividades que a continuación se indican',
        },
        popup: {
          close: 'Cerrar',
        },
        updateSuccess: 'Se ha suscrito correctamente a la newsletter',
        updateError: 'Ha habido un error al intentar suscribir el email a la newsletter. Por favor, inténtelo de nuevo más tarde.',
      },
    },
    news: {
      news: {
        readMore: 'Leer más',
        filterNewsTitle: 'Título de la noticia',
        filterRemove: 'Borrar filtro',
        filterDate: 'Filter',
        numberToShow: 'Mostrar',
        search: 'Filtrar',
        categories: 'Categorías',
        allCategories: 'Todas',
        notFound: 'No se han encontrado noticias',
        filtersNotFound: 'No se han encontrado noticias con los filtros aplicados',
        filters: {
          category: {
            loading: 'Cargando...',
            ALL: 'Todas',
            BRANDS_AND_NEWS: 'Marcas y Novedades',
            LIFESTYLE: 'Estilo de vida',
            GASTRONOMY: 'Gastronomía',
            WINE_TOURISM_AND_VISITS: 'Enoturismo & Visitas',
            TRAINING_AND_INNOVATION: 'Formación e Innovación',
            MEDIA: 'Medios'
          }
        }
      },
    },
    recaptcha: {
      recaptcha: {
        scriptFailedToLoad: 'Error al cargar el script reCaptcha.',
        recaptchaTokenV2Failed: 'Verificar la validación de reCaptcha',
        invalidVersion: 'Versión {{version}} de reCaptcha inválida. Versiones disponibles: {{available}}',
        expired: 'Ha expirado la validación del reCaptcha ',
        fail: 'Ha habido un error con reCaptcha',
      }
    },
    login: {
      login: {
        btnTitle: 'Acceso',
      },
    },
    productCategories: {
      productCategories: {
        title: 'Categorías',
      },
    },
    preferenceCenter: {
      preferenceCenter: {
        form: {
          title: 'MI INFORMACIÓN PERSONAL',
          name: {
            label: 'Nombre',
            placeholder: 'Nombre',
          },
          surnames: {
            label: 'Apellido',
            placeholder: 'Apellido',
          },
          email: {
            label: 'Email',
            placeholder: 'Email',
            contact: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es',
          },
          birthDate: {
            label: 'Fecha de nacimiento',
            placeholder: 'dd/mm/aaaa',
          },
          province: {
            label: 'Provincia',
            placeholder: 'Provincia',
          },
          phoneNumber: {
            label: 'Teléfono móvil',
            placeholder: 'Teléfono móvil',
          },
          language: {
            label: 'Idioma',
            placeholder: 'Idioma',
          },
          unsubscribe: {
            title: 'ANULAR SUSCRIPCIÓN',
            placeholder: 'No deseo recibir más comunicaciones comerciales.',
          },
          update: 'ACTUALIZAR',
          preferenceUpdateSuccess: 'Datos personales actualizados con éxito',
          preferenceUpdateError: 'No se ha podido actualizar los datos',
        },
        languages: {
          en: 'Inglés',
          fr: 'Francés',
          gt: 'Gallego',
          de: 'Alemán',
          it: 'Italiano',
          pt: 'Portugués',
          es: 'Español',
        },
      },
    },
  },
};
