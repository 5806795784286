export const environment = {
  production: false,
  occBaseUrl: "https://api.c9guxrh1t0-osbornedi1-d1-public.model-t.cc.commerce.ondemand.com",
  paypal: {
    id: 'AeJSUlhxPsOVV5ISvFRwQGCLJ9o837meTo5Fee_wjZMRR9jmRMXqeJYrHt0uJSdY4s20kOe3Bl_rwa3m',
    src: 'https://www.paypal.com/sdk/js',
    installments: {
      active: true,
      threshold: 60.0,
    },
  },
  gtmId: {
    osbornestore: 'GTM-KDPL2VX',
    'osbornestore-public': 'GTM-W2MNPBQ',
    riofrio: 'GTM-WBCP6MX',
  },
  reCaptcha: {
    enabled: true,
    scriptSrc: 'https://www.google.com/recaptcha/api.js',
    onloadCallbackName: 'recaptchaCallback',
    v2: {
      siteKey: '6LfDaf8eAAAAAAFtczB04T0abSBTzDV3Q-0fybJE',
    },
    v3: {
      siteKey: '',
    }
  },
  emarsysConfig: {
    merchantId: '16AD13C5377829C9',
    testMode: true,
  },
};
