import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AsmAdapter } from '@spartacus/asm/core';
import { OccAsmAdapter } from '@spartacus/asm/occ';
import { translationChunksConfig } from '@spartacus/assets';
import { CheckoutDeliveryAddressAdapter } from '@spartacus/checkout/base/core';
import { CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
import { CheckoutPaymentService } from '@spartacus/checkout/base/core';
import {
  AuthConfig,
  DeferLoadingStrategy, FeaturesConfig,
  OccConfig,
  provideConfig, provideDefaultConfig, RoutingConfig, UserAddressAdapter
} from '@spartacus/core';
import { SelectiveCartService } from '@spartacus/cart/base/core';
import {
  defaultCmsContentProviders,
  ImageLoadingStrategy,
  LayoutConfig,
  layoutConfig,
  MediaConfig,
  mediaConfig,
  ViewConfig,
} from '@spartacus/storefront';
import { commonEnglish } from 'src/assets/translations/en/custom-configuration-common-en.module';
import { commonSpanish } from 'src/assets/translations/es/custom-configuration-common-es.module';
import { commonFrench } from 'src/assets/translations/fr/custom-configuration-common-fr.module';
import { environment } from 'src/environments/environment';
import { CustomOccGiftCardAdapter } from '../custom/cms-components/checkout/custom-gift-card/adapter/custom-gift-card.adapter';
import { CustomGiftCardAdapter } from '../custom/cms-components/checkout/custom-gift-card/conectors';
import { CustomOccBaseStoreAdapter } from '../custom/cms-components/header/base-store/adapter/custom-occ-base-store.adapter';
import { CustomBaseStoreAdapter } from '../custom/cms-components/header/base-store/connectors/custom-base-store.adapter';
import { CustomBaseModule } from '../custom/cms-components/header/base-store/custom-base-store.module';
import { CustomExtraAppConfig } from '../custom/config/custom-extra-app-config';
import { CustomCreditCardModule } from './custom/cms-components/checkout/components/payment-method/custom-credit-card/custom-credit-card.module';
import { CustomOccOrderActionsAdapter } from './custom/cms-components/myaccount/order/custom-order-actions/adapter/custom-occ-order-actions.adapter';
import { CustomOrderActionsAdapter } from './custom/cms-components/myaccount/order/custom-order-actions/connectors/custom-order-actions.adapter';
import { CustomOrderActionsEffects } from './custom/cms-components/myaccount/order/custom-order-actions/store/effects/custom-order-actions.effects';
import { CustomOccUserAddressAdapter } from './custom/cms-components/user/adapter/custom-occ-user-address.adapter';
import { CustomCartAdapter } from './custom/core/cart/conectors/cart/custom-cart.adapter';
import { CustomCartEntryAdapter } from './custom/core/cart/conectors/entry/custom-cart-entry.adapter';
import { CustomSelectiveCartService } from './custom/core/cart/facade/custom-selective-cart.service';
import { CustomSiteAdapter } from './custom/core/cart/site-context/connectors/custom-site.adapter';
import { CustomCartEntryEffects } from './custom/core/cart/store/effects/custom-cart-entry.effect';
import { CustomCartEffects } from './custom/core/cart/store/effects/custom-cart.effect';
import { CustomCheckoutAdapter } from './custom/core/checkout/connectors/custom-checkout.adapter';
import { CustomCheckoutEffects } from './custom/core/checkout/store/effects/custom-checkout.effect';
import { customDefaultPageMetaConfig } from './custom/core/cms/page/config/custom-default-page-meta.config';
import { CustomOccFriendsCheckoutAdapter } from './custom/core/custom-friends/custom-friends-checkout/adapter/custom-occ-friends-checkout.adapter';
import { CustomFriendsCheckoutAdapter } from './custom/core/custom-friends/custom-friends-checkout/connectors/custom-friends-checkout.adapters';
import { CustomUserFriendsAdapter } from './custom/core/custom-friends/custom-friends-users/adapter/custom-occ-friends.adapter';
import { CustomFriendsAdapter } from './custom/core/custom-friends/custom-friends-users/connectors/friends.adapter';
import { CustomFriendsUsersModule } from './custom/core/custom-friends/custom-friends-users/custom-friends-users.module';
import { CustomFriendsModule } from './custom/core/custom-friends/custom-friends.module';
import { CustomOccCartEntryAdapter } from './custom/core/occ/adapters/cart/custom-occ-cart-entry.adapter';
import { CustomOccCartAdapter } from './custom/core/occ/adapters/cart/custom-occ-cart.adapter';
import { CustomOccCheckoutDeliveryAdapter } from './custom/core/occ/adapters/checkout/custom-occ-checkout-delivery.adapter';
import { CustomOccCheckoutPaymentTypeAdapter } from './custom/core/occ/adapters/checkout/payment-type/custom-occ-checkout-payment-type.adapter';
import { CustomOccCheckoutAdapter } from './custom/core/occ/adapters/checkout/custom-occ-checkout.adapter';
import { CustomOccSiteAdapter } from './custom/core/occ/adapters/site-context/custom-occ-site.adapter';
import { CustomUserAddressAdapter } from './custom/core/user/connectors/address/custom-user-address.adapter';
import { CustomDeliveryCountriesEffects } from './custom/core/user/store/effetcs/custom-delivery-countries.effect';
import { CustomUserAddressesEffects } from './custom/core/user/store/effetcs/custom-user-addresses.effect';
import { CustomUserOrdersEffect } from './custom/core/user/store/effetcs/custom-user-orders.effect';
import { CustomOccAsmAdapter } from './custom/feature-libs/asm/occ/adapters/custom-occ-asm.adapter';
import { GIFTBOX_LANDING_URL_LABEL, GIFTBOX_WIZARD_URL_LABEL } from '../custom/cms-components/giftbox/giftbox-constants';
import {CustomCheckoutPaymentService} from "./custom/core/checkout/facade/custom-checkout-payment.service";
import { ReplenishmentOrderHistoryAdapter, ReplenishmentOrderHistoryConnector } from '@spartacus/order/core';
import { CustomCheckoutPaymentTypeAdapter } from './custom/core/checkout/connectors/payment-type/custom-checkout-payment-type.adapter';
import { CheckoutConfig, CheckoutStepType } from '@spartacus/checkout/base/root';
import { CustomCheckoutPaymentTypeConnector } from './custom/core/checkout/connectors/payment-type/custom-checkout-payment-type.connector';
import { CartConfig } from '@spartacus/cart/base/root';
import { CheckoutGuard } from '@spartacus/checkout/base/components';
import { CustomCheckoutGuard } from './custom/cms-components/checkout/guards/custom-checkout.guard';
import { RecaptchaVersion } from './features/recaptcha/recaptcha.model';

const cartFields = 'GIFT_BOX,entryGroups(DEFAULT,entries(GIFT_BOX,totalPrice(formattedValue),product(DEFAULT,images(FULL),stock(FULL),unitPrices(FULL)),basePrice(formattedValue,value),updateable,unit(FULL)),entryGroupNumber,label),potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(GIFT_BOX,totalPrice(formattedValue),product(images(FULL),stock(FULL),unitPrices(FULL),numberOfSlots),basePrice(formattedValue,value),updateable,unit(FULL)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue,value),user,saveTime,name,description,deliveryNoteObservations,paymentMode(FULL),onBehalfOf(FULL),deliveryAddress(DEFAULT,country(name),region(name),formattedAddress),deliveryMode,paymentInfo(FULL),paymentAddress(FULL)';
const baseStoreFields = 'baseStores(code,name,pickupStore,deliveryCountries(isocode,%20name))';

export const customSharedSpartacusConfigurationEffects = EffectsModule.forFeature([
  CustomCartEntryEffects,
  CustomCheckoutEffects,
  CustomCartEffects,
  CustomUserOrdersEffect,
  CustomDeliveryCountriesEffects,
  CustomOrderActionsEffects,
  CustomUserAddressesEffects,
]);

export const customSharedSpartacusConfigurationLayoutConfig = provideConfig({
  ...layoutConfig,
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '50px',
  },
} as LayoutConfig);

export const customSharedSpartacusConfigurationLayoutMediaConfig = provideConfig({
  ...mediaConfig,
  mediaFormats: {
    mobile: { width: 768 },
    tablet: { width: 992 },
    desktop: { width: 1200 },
    widescreen: { width: 1320 },
    // product media
    cartIcon: { width: 65 },
    thumbnail: { width: 96 },
    product: { width: 284 },
    zoom: { width: 515 },
  },
  imageLoadingStrategy: ImageLoadingStrategy.LAZY
} as MediaConfig);

export const customSharedSpartacusConfigurationConfig = {
  backend: {
    occ: {
      baseUrl: environment.occBaseUrl,
      endpoints: {
        addresses: 'users/${userId}/addresses?fields=FULL',
        addressesBasestore: 'users/${userId}/basestore/addresses?fields=FULL',
        addressCustomCreate: '/users/${userId}/basestore/addresses',
        addressCustomUpdate: '/users/${userId}/basestore/addresses/${addressId}',
        product: {
          default:
            'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),stock(DEFAULT),baseOptions,baseProduct,variantOptions(FULL),variantType,unitPrices(FULL)',
          list: 'products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),baseProduct,itemtype,stock(DEFAULT),unitPrices(FULL)',
          details:
            'products/${productCode}?fields=potentialPromotions(FULL),averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL),brands(DEFAULT),metaDescription,metaTitle,unitPrices,names,labelProduct(DEFAULT),variantOptions(FULL),unitPrices(FULL)',
          attributes: 'products/${productCode}?fields=classifications',
          specifications: 'products/${productCode}?fields=specifications(DEFAULT)',
          prices: 'products/${productCode}?fields=unitPrices(FULL),stock(DEFAULT)',
          variants: 'products/${productCode}?fields=name,purchasable,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType,variantCode,categories(FULL),principalVariant,unitPrices(FULL)',
        },
        paymentMethods: '/users/${userId}/carts/${cartId}/availablePaymentModes',
        cartDeliveryAddress: 'users/${userId}/carts/${cartId}/addresses/delivery/custom',
        paymentMode: 'users/${userId}/carts/${cartId}/paymentMode',
        carts: 'users/${userId}/carts?fields=carts(' + cartFields + ')',
        cart: 'users/${userId}/carts/${cartId}?fields=' + cartFields,
        createCart:
          'users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),unitPrices(FULL)),basePrice(formattedValue,value),updateable,unit(FULL)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        addEntries: 'users/${userId}/carts/${cartId}/entries/unit',
        wishlist: 'users/${userId}/wishlists',
        wishlists: 'users/${userId}/wishlists?fields=DEFAULT,entries(DEFAULT,product(DEFAULT,summary,images(FULL),stock(FULL),unitPrices(FULL)))',
        validateCart: '/users/${userId}/carts/${cartId}/validate',
        productSearch:
          'products/search?fields=GIFT_BOX,products(code,name,categories(DEFAULT),brands(DEFAULT),summary,configurable,configuratorType,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions(FULL),unitPrices(FULL),labelProduct(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery,categoryDescription',
        redsysPayment: 'users/${userId}/carts/${cartId}/payments/redsys/hop',
        paypalOrderCreate: 'users/${userId}/carts/${cartId}/paypal/order/create',
        paypalOrderCapture: 'users/${userId}/carts/${cartId}/paypal/order/capture',
        addOnBehalfOf: '/users/${userId}/carts/${cartId}/onbehalfof?onBehalfOfUid=${onBehalfOfUid}',
        onBehalfOfCandidates: 'users/${userId}/carts/${cartId}/onbehalfofcandidates?fields=FULL',
        onBehalfOf: 'users/${userId}/carts/${cartId}/onbehalfof',
        removeOnBehalfOf: '/users/${userId}/carts/${cartId}/onbehalfof',
        giftCard: 'users/${userId}/carts/${cartId}/giftCard',
        getGiftCard: 'users/${userId}/carts/${cartId}/giftCard?fields=GIFT_CARD',
        giftCards: 'users/${userId}/carts/${cartId}/giftCards?fields=GIFT_CARD',
        friendsAll: 'users/${userId}/onbehalfofcandidates',
        friends: 'users/${userId}/friends',
        guestFriendInvitation: '/friends?fields=DEFAULT&token=${token}',
        invitation: '/users/${userId}/friends/${friendId}/invite',
        friendId: 'users/${userId}/friends/${friendId}',
        countries: '/basestore/countries',
        countriesAll: 'countries?type=${type}',
        simulate: '/users/${userId}/carts/${cartId}/simulate?fields=' + cartFields,
        user: 'users/${userId}/custom',
        baseStore: 'users/${userId}/basestores?fields=' + baseStoreFields,
        baseStoreAnonymous: '/basestores?fields=' + baseStoreFields,
        currentBaseStore: '/basestores/${baseStoreUid}?fields=name,code,pickupStore',
        orderHistoryFilter: 'users/${userId}/orders/custom',
        orderDetail: 'users/${userId}/orders/${orderId}?fields=FULL',
        orderCancellation: '/users/${userId}/orders/${code}/cancellation/custom',
        orderRestoration: '/users/${userId}/orders/${code}/restoration?fields=' + cartFields,
        orderTicket: '/users/${userId}/orders/${code}/ticket',
        billHistory: 'users/${userId}/invoices',
        statisticsValue: '/users/${userId}/statistics/value${params}',
        statisticsUnits: '/users/${userId}/statistics/units${params}',
        creditCardList: '/users/${userId}/paymentdetails',
        savedCreditCard: '/users/${userId}/paymentdetails/${paymentDetailsId}',
        updateSavedCreditCard: '/users/${userId}/carts/${cartId}/paymentdetails?paymentDetailsId=${paymentDetailsId}',
        addGiftbox: 'users/${userId}/giftboxes?fields=GIFT_BOX,entryGroups(DEFAULT,entries(DEFAULT,totalPrice(formattedValue),product(DEFAULT,images(FULL),stock(FULL),unitPrices(FULL)),basePrice(formattedValue,value),updateable,unit(FULL)),entryGroupNumber,label),potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),unitPrices(FULL),numberOfSlots),basePrice(formattedValue,value),updateable,unit(FULL)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,deliveryNoteObservations,paymentMode(FULL),onBehalfOf(FULL),deliveryAddress(DEFAULT,country(name),region(name),formattedAddress),deliveryMode,paymentInfo(FULL)',
        emails: '/users/${userId}/emails',
        news: '/news',
        newsDetails: '/news/${newsId}',
        newsCategories: '/news/categories',
        consents: '/guests/consents',
        billingAddress: '/users/${userId}/carts/${cartId}/addresses/payment',
        documentIdentifier: '/users/${userId}/carts/${cartId}/documentIdentifier',
        csTicket: 'cstickets?fields=${templateConfiguration}',
        csTicketCauses: 'cstickets/causes?fields=DEFAULT&templateConfigurations=${templateConfigurations}',
        orderReturns: 'users/${userId}/orderReturns?fields=DEFAULT',
        returnOrder: 'users/${userId}/orderReturns?fields=BASIC,returnEntries(BASIC,refundAmount(formattedValue),orderEntry(basePrice(formattedValue,value),product(categories(DEFAULT),brands(DEFAULT),unitPrices(FULL),name,code,baseOptions,images(DEFAULT,galleryIndex)))),deliveryCost(formattedValue),totalPrice(formattedValue),subTotal(formattedValue)',
        getCustomCheckoutDetails:
          'users/${userId}/carts/${cartId}?fields=deliveryAddress(FULL),deliveryMode(FULL),paymentMode(FULL)',
        validateDocIdentifierAndTotalAmount: '/users/${userId}/carts/${cartId}/validateDocIdentifierAndTotalAmount',
      },
    },
  },
  smartEdit: {
    storefrontPreviewRoute: 'cx-preview',
    allowOrigin: 'localhost:9002,*.*.model-t.cc.commerce.ondemand.com:443',
  },
  i18n: {
    resources: {
      ...commonSpanish,
      ...commonEnglish,
      ...commonFrench,
    },
    chunks: translationChunksConfig,
    fallbackLang: 'en',
  },
  features: {
    level: '6.0',
  },
  view: {
    infiniteScroll: {
      active: true,
      productLimit: 200,
      showMoreButton: false,
    },
    customViewConfig: {
      defaultPageSize: 12,
      infiniteScroll: {
        active: true,
        productLimit: 200,
        showMoreButton: false,
      },
    },
  },
  seo: {
    structuredData: {
      disableInDevMode: false,
    },
  },
  ...customDefaultPageMetaConfig,
};

export const customRoutes = {
  product: {
    paths: [
      'tienda-online/product/:productCode/:slug',
      'tienda-online/product/:productCode/:name',
      'tienda-online/product/:productCode',
      'shop-online/product/:productCode/:slug',
      'shop-online/product/:productCode/:name',
      'shop-online/product/:productCode',
      'boutique-online/product/:productCode/:slug',
      'boutique-online/product/:productCode/:name',
      'boutique-online/product/:productCode',
    ],
    paramsMapping: { productCode: 'code' },
  },
  // plp routes
  search: {
    paths: [
      'tienda-online/search/:query',
      'shop-online/search/:query',
      'boutique-online/search/:query',
    ]
  },
  category: {
    paths: [
      'tienda-online/category/:categoryCode',
      'tienda-online/c/:categoryCode',
      'shop-online/category/:categoryCode',
      'shop-online/c/:categoryCode',
      'boutique-online/category/:categoryCode',
      'boutique-online/c/:categoryCode',
    ],
    paramsMapping: { categoryCode: 'code' },
  },
  brand: {
    paths: [
      'tienda-online/Brands/:brandName/c/:brandCode',
      'shop-online/Brands/:brandName/c/:brandCode',
      'boutique-online/Brands/:brandName/c/:brandCode',
    ]
  },
  orderConfirmation: {
    paths: [
      'order-confirmation/:orderCode',
      'order-confirmation',
    ],
    paramsMapping: { orderCode: 'code' },
  },
  restoreCart: {
    paths: [
      'restore-cart/:cartCode',
      'restore-cart',
    ],
    paramsMapping: { cartCode: 'code' },
  },
  registerFriend: { paths: ['login/register/friend'], protected: false },
  friends: { paths: ['my-account/friends'] },
  bills: { paths: ['my-account/bills'] },
  statistics: { paths: ['my-account/statistics'] },
  giftbox: { paths: [GIFTBOX_LANDING_URL_LABEL] },
  giftboxWizard: { paths: [GIFTBOX_WIZARD_URL_LABEL] },
  orderReturns: { paths: ['my-account/returns'] },
  checkoutLogin: { paths: ['checkout-login'], authFlow: true },
  checkout: { paths: ['checkout'] },
  checkoutDeliveryAddress: { paths: ['checkout/shipping-address'] },
  checkoutDeliveryMode: { paths: ['checkout/delivery-mode'] },
  checkoutPaymentDetails: { paths: ['checkout/payment-type'] },
  checkoutReviewOrder: { paths: ['checkout/review-order'] },
  preferenceCenter: { paths: ['centrodepreferencias'], protected: false, authFlow: true },
};

export const customSharedSpartacusConfigurationProviders = [
  {
    provide: CustomCartEntryAdapter,
    useClass: CustomOccCartEntryAdapter,
  },
  {
    provide: CustomCartAdapter,
    useClass: CustomOccCartAdapter,
  },
  {
    provide: CheckoutPaymentService,
    useClass: CustomCheckoutPaymentService,
  },
  {
    provide: CustomCheckoutAdapter,
    useClass: CustomOccCheckoutAdapter,
  },
  {
    provide: CheckoutPaymentTypeAdapter,
    useClass: CustomOccCheckoutPaymentTypeAdapter,
  },
  {
    provide: CustomCheckoutPaymentTypeAdapter,
    useClass: CustomOccCheckoutPaymentTypeAdapter,
  },
  {
    provide: CustomFriendsCheckoutAdapter,
    useClass: CustomOccFriendsCheckoutAdapter,
  },
  {
    provide: CustomGiftCardAdapter,
    useClass: CustomOccGiftCardAdapter,
  },
  {
    provide: CustomFriendsAdapter,
    useClass: CustomUserFriendsAdapter,
  },
  {
    provide: CustomBaseStoreAdapter,
    useClass: CustomOccBaseStoreAdapter,
  },
  {
    provide: CustomSiteAdapter,
    useClass: CustomOccSiteAdapter,
  },
  {
    provide: CustomOrderActionsAdapter,
    useClass: CustomOccOrderActionsAdapter,
  },
  {
    provide: UserAddressAdapter,
    useClass: CustomOccUserAddressAdapter,
  },
  {
    provide: CustomUserAddressAdapter,
    useClass: CustomOccUserAddressAdapter,
  },
  {
    provide: CheckoutDeliveryAddressAdapter,
    useClass: CustomOccCheckoutDeliveryAdapter,
  },
  {
    provide: SelectiveCartService,
    useClass: CustomSelectiveCartService,
  },
  {
    provide: AsmAdapter,
    useClass: CustomOccAsmAdapter,
  },
  {
    provide: OccAsmAdapter,
    useClass: CustomOccAsmAdapter,
  },
  {
    provide: ReplenishmentOrderHistoryConnector,
    useClass: ReplenishmentOrderHistoryAdapter
  },
  {
    provide: CheckoutGuard,
    useClass: CustomCheckoutGuard
  },
  CustomCheckoutPaymentTypeConnector
];

export const customCheckoutConfig: CheckoutConfig = {
  checkout: {
    steps: [
      {
        id: 'deliveryAddress',
        name: 'checkoutProgress.shippingAddress',
        routeName: 'checkoutDeliveryAddress',
        type: [CheckoutStepType.DELIVERY_ADDRESS],
      },
      {
        id: 'deliveryMode',
        name: 'checkoutProgress.deliveryMode',
        routeName: 'checkoutDeliveryMode',
        type: [CheckoutStepType.DELIVERY_MODE],
      },
      {
        id: 'paymentDetails',
        name: 'checkoutProgress.methodOfPayment',
        routeName: 'checkoutPaymentDetails',
        type: [CheckoutStepType.PAYMENT_DETAILS, CheckoutStepType.REVIEW_ORDER],
      }
    ],
    guest: true,
  }
};

@NgModule({
  declarations: [],
  imports: [
    customSharedSpartacusConfigurationEffects,
    CustomFriendsModule,
    CustomFriendsUsersModule,
    CustomBaseModule,
    CustomCreditCardModule,
  ],
  providers: [
    customSharedSpartacusConfigurationLayoutConfig,
    customSharedSpartacusConfigurationLayoutMediaConfig,
    ...defaultCmsContentProviders,
    provideConfig(<RoutingConfig>{
      routing: {
        protected: true,
        routes: {
          public: {
            paths: ['public/:label'],
            paramsMapping: { label: 'code' },
            protected: false,
          },
          ...customRoutes,
          register: { paths: ['login/register'], protected: true },
        }
      }
    }),
    provideDefaultConfig({
      enableResetViewOnNavigate: {
        active: true,
        ignoreQueryString: true,
        ignoreRoutes: [],
      },
    }),
    provideConfig({
      customExtraApp: {
        enabledCustomerRegister: false,
        allowFriends: true,
        checkoutBilling: true,
        extendedLoginInfo: false,
        miniCartPreview: true,
        showAgePopup: false,
        reCaptcha: {
          enabled: true,
          version: RecaptchaVersion.V2,
        },
      }
    } as CustomExtraAppConfig),
    provideConfig({
      authentication: {
        client_id: 'osborne_private',
        client_secret: 'secret',
        tokenEndpoint: '/oauth/token',
        revokeEndpoint: '/oauth/revoke',
        loginUrl: '/oauth/authorize',
        OAuthLibConfig: {
          scope: '',
          customTokenParameters: ['token_type'],
          strictDiscoveryDocumentValidation: false,
          skipIssuerCheck: true,
          disablePKCE: true,
          oidc: false,
          clearHashAfterLogin: false,
        },
      },
    } as AuthConfig),
    provideConfig({
      ...customSharedSpartacusConfigurationConfig,
      context: {
        currency: ['EUR', 'USD'],
        language: ['es', 'en'],
        urlParameters: ['language'],
      },
    }),
    provideConfig(customCheckoutConfig),
    ...customSharedSpartacusConfigurationProviders,
    provideDefaultConfig(<CartConfig> {
      cart: {
        selectiveCart: {
          enabled: false,
        }
      }
    }),
    provideConfig(<ViewConfig>{
      view: {
        infiniteScroll: {
          active: true,
          productLimit: 200,
          showMoreButton: false,
        },
      },
    }),
  ],
})
export class SpartacusConfigurationModule {
}
